<template>
    <div id="index" ref="appRef">
        <dv-loading v-show="loading">Loading...</dv-loading>
        <div class="bg">
            <img ref="conf0" style="display: none;" src="@/assets/BMS/Group 24@2x.png">
            <img ref="conf1" style="display: none;" src="@/assets/BMS/icon_光圈@2x.png">

            <!-- 头部 -->
            <div class="head">
                <div style="position: absolute;top: 50%;left: 30px;color: #65ffff;font-size: 32px;display: flex;flex-direction: row;align-items: center;"
                    @click="back">
                    <span>◁</span><span style="margin-top: -4px;">━</span> 
                    <span v-if="lang == 'zh'" style="margin-left: 8px;font-size: 22px;">返回</span>
                    <span v-else style="margin-left: 8px;font-size: 22px;">go back</span>
                </div>
                <div style="font-size: 50px;text-align: center;color: #fff;">BMS电池监测界面</div>
                <div style="font-size: 27px;text-align: center;color: #fff;">BMS BATTERY MONITORING INTERFACE</div>
            </div>


            <div style="display: flex;height: 82%;">
                <!-- 左侧 -->
                <div style="flex: 1;" class="left">
                    <!-- <div style="padding: 20px;"> -->
                        <div v-for="(a, i) in deviceS" :key="i" :class="currentIndex == i ? 'leftItemBg' : 'leftItem'" @click="getData(a.id, i)">
                            <span style="width: 200px;" class="ellipsis-multiline">{{ a.name }}</span>
                            <span style="font-size: 14px;">设备ID：{{ a.deviceName }}</span>
                        </div>

                        <div class="switchBtn">
                            <div class="next" @click="next('up')">
                                {{lang == 'zh' ? '上一页' : 'up'}}
                            </div>

                            <ul class="pagination">
                                <!-- 起始页码 -->
                                <li v-if="showStartDots" class="page-item_dots">...</li>

                                <!-- 显示的页码 -->
                                <li v-for="page in visiblePages()" :key="page" class="pageNumber"
                                    :class="{ 'page-link': page === currentPage }" @click="goToPage(page)">
                                    {{ page }}
                                </li>

                                <!-- 中间省略号 -->
                                <li v-if="showMiddleDots" class="page-item_dots">...</li>

                                <!-- 末尾页码 -->
                                <li v-if="showEndDots" class="page-item_dots">...</li>

                            </ul>

                            <!-- <div class="pageNumber">1</div>
                            <div class="pageNumber">2</div>
                            <div class="pageNumber">3</div> -->

                            <div class="next" @click="next('down')">
                                {{lang == 'zh' ? '下一页' : 'down'}}
                            </div>
                        </div>
                    <!-- </div> -->
                </div>

                <!-- 中间 -->
                <div style="flex: 2;">
                    <div style="display: flex;flex-direction: column;align-items: center;width: 100%;">
                        <div style="margin-top: 5px;" v-show="current % 2 == 0">
                            <canvas id="myCanvas" ref="myCanvas" width="600" height="600"></canvas>
                        </div>

                        <div class="centBot"
                            style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;width: 100%;margin-top: 30px;">
                            <div class="centItem" style="">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <p>{{ batteryData.battemp ? batteryData.battemp : 0 }}</p>
                                    <p style="font-size: 20px;">{{lang == 'zh' ? '温度' : 'temperature'}}(°C)</p>
                                </div>
                            </div>

                            <div class="centItem" v-if="!batteryData.sun_cur">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <p>{{ batteryData.running_time }}</p>
                                    <p style="font-size: 20px;">{{lang == 'zh' ? '运行时间' : 'run time'}}</p>
                                </div>
                            </div>
                            <div class="vp" v-if="batteryData.sun_cur">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <img src="../../assets/BMS/icon@2x.png" style="width: 60px;height: 60px;" alt="">
                                    <p style="font-size: 22px;">{{ batteryData.sun_vin ? (batteryData.sun_vin / 1000) : 0 }}</p>
                                    <p style="font-size: 20px;">{{lang == 'zh' ? 'PV电压' : 'PV voltage'}}</p>
                                </div>
                            </div>

                            <div class="centItem" style="">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <p>{{ batteryData.dumpEnergy ? batteryData.dumpEnergy : 0 }}</p>
                                    <p style="font-size: 20px;">{{lang == 'zh' ? '总电压' : 'total voltage'}}(v)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 右侧 -->
                <div style="flex: 1;" class="right">
                    <div class="rightTop">
                        <div class="item">
                            <p>{{ Battery ? Battery : 0 }}<span style="font-size: 16px;">%</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '剩余电量' : 'Battery'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ batteryData.dischargePower ? batteryData.dischargePower  : 0}}<span style="font-size: 16px;">w</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '功率' : 'power'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ differentialPressure ? differentialPressure : 0 }}<span style="font-size: 16px;">v</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '压差' : 'differential pressure'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ batteryData.sun_charge_Power_day ? batteryData.sun_charge_Power_day : 0 }}</p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '光伏日发电量(Wh)' : 'photovoltaics(Wh)'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ maxVoltage ? maxVoltage : 0 }}<span style="font-size: 16px;">v</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '单体最高电压' : 'ceiling voltage'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ minVoltage ? minVoltage : 0 }}<span style="font-size: 16px;">v</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '单体最低电压' : 'minimum voltage'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ batteryData.chargePower ? batteryData.chargePower : 0 }}<span
                                    style="font-size: 16px;">wh</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '充电电量' : 'Charging capacity'}}</p>
                        </div>

                        <div class="item">
                            <p>{{ batteryData.batEcele_day ? batteryData.batEcele_day : 0 }}<span
                                    style="font-size: 16px;">wh</span></p>
                            <p style="font-size: 16px;color: #fff;">{{lang == 'zh' ? '放电电量' : 'Discharge capacity'}}</p>
                        </div>
                    </div>

                    <div class="rightButton">
                        <div class="item">
                            <p style="font-size: 24px;">1</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_1_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_1_vol ? batteryData.bat_1_vol : 0 }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">2</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_2_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_2_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">3</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_3_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_3_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">4</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_4_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_4_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">5</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_5_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_5_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">6</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_6_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_6_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">7</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_7_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_7_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item">
                            <p style="font-size: 24px;">8</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_8_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_8_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>

                        <div class="item" v-if="batteryData.bat_9_soc != null && batteryData.bat_9_soc > 2">
                            <p style="font-size: 24px;">9</p>
                            <div class="battery">
                                <div class="batteryNum">
                                    <div class="win" :style="{ 'width': batteryData.bat_9_soc + '%' }"></div>
                                    <p class="tet">{{ batteryData.bat_9_vol }}</p>
                                </div>
                            </div>
                            <img style="width: 22px;height: 22px;" src="@/assets/BMS/Frame@2x.png" alt="" srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import drawMixin from "../../utils/drawMixin";

import { deviceListAPI, batteryAPI } from "@/api";

export default {
    name: 'BMSindex',
    mixins: [drawMixin],
    data() {
        return {
            loading: true,
            lang: 'zh',
            project_id: '',
            currentMax: 20, // 最大电流值
            current: 0, // 电流值.

            minVoltage: 0, // 最小电压
            maxVoltage: 0, // 最大电压
            differentialPressure: 0, // 压差
            Battery: 0, // 剩余电量

            contextCanvas: null,
            deviceS: [],
            batteryData: {},

            currentIndex: 0,  // 当前显示的
            currentPage: 1,
            totalPages: 9, // 假设总页数是 100
            pageSize: 4, // 每页显示 5 个页码
            showStartDots: false,
            showMiddleDots: true,
            showEndDots: false,
        }
    },

    mounted() {
        if (localStorage.getItem('lang')) {
            this.lang = localStorage.getItem('lang')
        }
        let query = this.$route.query; // { key1: value1, key2: value2 }
        
        if(query.g) {
            this.grouping_id = query.g
        }else {
            this.project_id = query.p
        }

        this.deviceList()

        var c = document.getElementById('myCanvas');
        this.contextCanvas = c.getContext("2d");
        this.draw()

    },
    methods: {
        // 返回
        back() {
            this.$router.back();
        },
        // 设备列表
        async deviceList() {
            this.loading = true
            let obj = { project_id: this.project_id, page: this.currentPage }
            if(this.grouping_id) obj = { grouping_id: this.grouping_id, page: this.currentPage }

            const res = await deviceListAPI(obj);
            console.log(res);
            this.deviceS = res.data
            if (this.deviceS.length == 0) {
                alert('暂无数据')
                this.back()
                return
            }
            this.totalPages = res.last_page

            this.getData(res.data[0].id, 0)
        },
        // 获取数据
        async getData(id, index) {
            this.loading = true
            this.currentIndex = index
            const res = await batteryAPI({ id: id });
            // console.log(res.data.data);
            this.batteryData = res

            this.seekMaxMin()
            this.BatteryFun()
            setTimeout(() => {

                this.$nextTick(() => {
                    this.contextCanvas.clearRect(0, 0, 600, 600);

                    this.contextCanvas.beginPath();

                    let current = 0
                    // 当前电流
                    if (this.batteryData.batchargeCurrent != 0) {
                        current = this.batteryData.batchargeCurrent.toFixed(1)
                    }
                    // this.batteryData.sun_cur = 1
                    // 当前 VP电流
                    if(this.batteryData.sun_cur) {
                        this.batteryData.sun_cur = this.batteryData.sun_cur / 1000
                        current = this.batteryData.sun_cur.toFixed(2)
                    }

                    // current = 11.9

                    // 重新绘制
                    var center_data_font1 = '80px Arial'
                    var center_data_font2 = '30px Arial'
                    var circle_width = 22 // 仪表盘边宽

                    var circle = { // 定义圆
                        x: 600,
                        y: 600,
                        radius: 340
                    }

                    // 绘制背景图片
                    var img = this.$refs.conf0

                    // VP 背景图
                    if(this.batteryData.sun_cur) {
                        img = this.$refs.conf1
                    }
                    

                    // 绘制图片到canvas上
                    this.contextCanvas.drawImage(img, 0, 0, circle.x, circle.y);

                    // 绘制底环
                    this.contextCanvas.beginPath();
                    this.contextCanvas.arc(circle.x / 2, circle.y / 2, circle.radius / 2, 0.75 * Math.PI, 0.25 * Math.PI);
                    this.contextCanvas.lineWidth = circle_width
                    this.contextCanvas.strokeStyle = 'transparent';  // transparent
                    this.contextCanvas.stroke();

                    // 绘制进度圆环
                    this.contextCanvas.beginPath();
                    this.contextCanvas.arc(circle.x / 2, circle.y / 2, circle.radius / 2, 0.75 * Math.PI, (0.75 * Math.PI + (1.5 * (current / this.currentMax)) * Math.PI).toFixed(2));
                    this.contextCanvas.lineWidth = circle_width

                    // 圆环颜色
                    let cor = '#65ffff'
                    // VP 圆环颜色
                    if(this.batteryData.sun_cur) {
                        cor = '#f1b560'
                    }
                    this.contextCanvas.strokeStyle = cor;

                    this.contextCanvas.stroke();

                    // 绘制中间文本
                    var centerText_x = circle.x - 40
                    var centerText_Y = circle.y / 2

                    this.contextCanvas.font = center_data_font1
                    var textWidth = this.contextCanvas.measureText(current).width.toFixed(0)
                    console.log('textWidth', textWidth)
                    var textX = (centerText_x - textWidth) / 2;
                    this.contextCanvas.fillStyle = '#fff'
                    this.contextCanvas.fillText(current, textX, centerText_Y);

                    var textWidth2 = this.contextCanvas.measureText('A').width;
                    var textX2 = (centerText_x - textWidth2 + textWidth) / 2 + 20;


                    if (textWidth <= 50) {
                        this.contextCanvas.font = center_data_font2
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText('A', textX2 + 40, centerText_Y);

                        this.contextCanvas.font = center_data_font2
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText( this.lang == 'zh' ? '工作电流' : 'Working current', textX, centerText_Y + 45);
                    } else if (textWidth >= 150) {
                        this.contextCanvas.font = center_data_font2
                        textWidth2 = this.contextCanvas.measureText('A').width;
                        textX2 = (centerText_x - textWidth2 + textWidth) / 2 + 100;
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText('A', textX2, centerText_Y);

                        this.contextCanvas.font = center_data_font2
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText( this.lang == 'zh' ? '工作电流' : 'Working current', textX + 40, centerText_Y + 45);
                    } else if (textWidth > 100) {
                        this.contextCanvas.font = center_data_font2
                        textWidth2 = this.contextCanvas.measureText('A').width;
                        textX2 = (centerText_x - textWidth2 + textWidth) / 2 + 80;
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText('A', textX2, centerText_Y);

                        this.contextCanvas.font = center_data_font2
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText( this.lang == 'zh' ? '工作电流' : 'Working current', textX + 20, centerText_Y + 45);
                    } else if (textWidth <= 100) {
                        this.contextCanvas.font = center_data_font2
                        textWidth2 = this.contextCanvas.measureText('A').width;
                        textX2 = (centerText_x - textWidth2 + textWidth) / 2 + 70;
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText('A', textX2, centerText_Y);

                        this.contextCanvas.font = center_data_font2
                        this.contextCanvas.fillStyle = '#fff'
                        this.contextCanvas.fillText( this.lang == 'zh' ? '工作电流' : 'Working current', textX + 20, centerText_Y + 45);
                    }


                })

                this.loading = false;

            }, 1000)

        },

        goToPage(page) {
            if (page < 1 || page > this.totalPages || page === this.currentPage) return;
            this.currentPage = page;

            this.deviceList()
        },
        // 剩余电量
        BatteryFun() {
            let arr = []
            let ii = 8
            if ((this.batteryData[`bat_9_vol`] / 1000).toFixed(3) > 2) {
                ii = 9
            }
            for (var i = 1; i <= ii; i++) {
                if (this.batteryData[`bat_${i}_soc`]) {
                    arr.push(this.batteryData[`bat_${i}_soc`] * 1)
                }
            }
            arr.sort((a, b) => a - b); // 升序
            // 移除最大值和最小值
            arr.shift(); // 移除最小值
            arr.pop();   // 移除最大值
            // 计算剩余元素的平均值
            // console.log('剩余电量', arr)
            const sum = arr.reduce((total, num) => total + num, 0);
            // console.log('剩余电量', sum)
            this.Battery = (sum / arr.length).toFixed(0)
            console.log(this.Battery)
        },
        // 查找最大值和最小值
        seekMaxMin() {
            let arr = []
            let ii = 8
            
            if ((this.batteryData[`bat_9_vol`] / 1000).toFixed(3) > 2) {
                ii = 9
            }
            for (var i = 1; i <= ii; i++) {
                if (this.batteryData[`bat_${i}_vol`]) {
                    this.batteryData[`bat_${i}_vol`] = (this.batteryData[`bat_${i}_vol`] / 1000).toFixed(3)
                    arr.push(this.batteryData[`bat_${i}_vol`])
                }
            }
            arr.sort((a, b) => a - b); // 升序
            // console.log(arr, '查找最大值和最小值')
            this.minVoltage = arr[0]
            this.maxVoltage = arr[arr.length - 1]
            this.differentialPressure = (this.maxVoltage - this.minVoltage).toFixed(3)
            console.log(this.minVoltage, this.maxVoltage)
        },

        // 换也
        next(type) {
            if (type == 'up') {
                if (this.currentPage > 1) {
                    this.currentPage--
                } else {
                    return
                }
            } else {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++
                } else {
                    return
                }
            }

            this.deviceList()
        },

        draw() {
            var center_data_font1 = '80px Arial'
            var center_data_font2 = '30px Arial'
            var circle_width = 22 // 仪表盘边宽

            var circle = { // 定义圆
                x: 600,
                y: 600,
                radius: 340
            }

            // 绘制背景图片
            var img = this.$refs.conf0
            img.onload = () => {
                // 绘制图片到canvas上
                this.contextCanvas.drawImage(img, 0, 0, circle.x, circle.y);

                // 绘制底环
                this.contextCanvas.beginPath();
                this.contextCanvas.arc(circle.x / 2, circle.y / 2, circle.radius / 2, 0.75 * Math.PI, 0.25 * Math.PI);
                this.contextCanvas.lineWidth = circle_width
                this.contextCanvas.strokeStyle = 'transparent';  // transparent
                this.contextCanvas.stroke();

                // 绘制进度圆环
                this.contextCanvas.beginPath();
                this.contextCanvas.arc(circle.x / 2, circle.y / 2, circle.radius / 2, 0.75 * Math.PI, (0.75 * Math.PI + (1.5 * (this.current / this.currentMax)).toFixed(2) * Math.PI));
                this.contextCanvas.lineWidth = circle_width
                this.contextCanvas.strokeStyle = '#65ffff';
                this.contextCanvas.stroke();

                // 绘制中间文本
                var centerText_x = circle.x - 40
                var centerText_Y = circle.y / 2

                this.contextCanvas.font = center_data_font1
                var textWidth = this.contextCanvas.measureText(this.current).width;
                var textX = (centerText_x - textWidth) / 2;
                this.contextCanvas.fillStyle = '#fff'
                this.contextCanvas.fillText(this.current, textX, centerText_Y);

                this.contextCanvas.font = center_data_font2
                var textWidth2 = this.contextCanvas.measureText('A').width;
                var textX2 = (centerText_x - textWidth2 + textWidth) / 2 + 20;
                this.contextCanvas.fillStyle = '#fff'
                this.contextCanvas.fillText('A', textX2, centerText_Y);

                this.contextCanvas.font = center_data_font2
                this.contextCanvas.fillStyle = '#fff'
                this.contextCanvas.fillText('充电电流', textX - 20, centerText_Y + 45);
            };

        },






        visiblePages() {
            const start = Math.max(1, this.currentPage - Math.floor(this.pageSize / 2));
            const end = Math.min(this.totalPages, start + this.pageSize - 1);

            this.showStartDots = start > 1;
            this.showMiddleDots = end < this.totalPages && (start > 2 || end < this.totalPages - 1);
            this.showEndDots = end < this.totalPages - 1;

            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        },


        // 转换00:00格式
        secondsToHHMMSS(sec) {
            let hours = Math.floor(sec / 3600);
            let minutes = Math.floor((sec - (hours * 3600)) / 60);
            let seconds = sec % 60;

            // 如果小时数大于0，则格式为HH:MM:SS
            if (hours > 0) {
                return [hours, minutes, seconds]
                    .map(num => num < 10 ? '0' + num : num)
                    .join(':');
            } else {
                // 如果小时数为0，则格式为MM:SS
                return [minutes, seconds]
                    .map(num => num < 10 ? '0' + num : num)
                    .join(':');
            }
        },

    },
};
</script>

<style lang="scss" scoped>
#index {
    color: #d3d6dd;
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 100%;
        padding: 16px 16px 0 16px;
        background-image: url("../../assets/pageBg.png");
        background-size: cover;
        background-position: center center;
    }
}

.head {
    position: relative;
    width: 100%;
    height: 11vh;
    background: url("../../assets/BMS/Group 70@2x.png") no-repeat;
    background-size: cover;
    background-position: center center;
}

.left {
    width: 100%;
    height: 100%;
    // padding: 16px 16px 0 16px;
    background: url("../../assets/BMS/Group@2x.png") no-repeat;
    background-size: contain;
    background-position: center 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    .leftItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background: url("../../assets/BMS/Group 73@2x.png") no-repeat;
        background-size: contain;
        background-position: center 0px;
        width: 82%;
        height: 95px;
        margin-bottom: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
    }

    .leftItemBg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background: url("../../assets/BMS/Group 73@2x2.png") no-repeat;
        background-size: contain;
        background-position: center 0px;
        width: 82%;
        height: 95px;
        margin-bottom: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
    }

    .switchBtn {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);

        .pagination {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .pageNumber {
                background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
                padding: 5px 10px;
            }

            .page-item_dots {
                background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
                padding: 5px 10px;
            }

            .page-link {
                color: #66ffff;
            }
        }

        .next {
            background: url("../../assets/BMS/Rectangle 38@2x.png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            padding: 4px 10px;
        }
    }
}

.centBot {


    .centItem {
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 32px;
        background: url("../../assets/BMS/Group 71@2x.png") no-repeat;
        background-size: contain;
        background-position: center 0px;
        padding: 4px 10px;
    }

    .vp {
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 32px;
        background: url("../../assets/BMS/Frame 23@2x.png") no-repeat;
        background-size: contain;
        background-position: center 0px;
        padding: 4px 10px;
    }
}

.right {
    width: 100%;
    height: 100%;
    background: url("../../assets/BMS/Group@2x.png") no-repeat;
    background-size: contain;
    background-position: center 0px;
    padding-top: 20px;

    .rightTop {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;


        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 190px;
            height: 100px;
            margin-bottom: 14px;
            background: url("../../assets/BMS/Group@2x(1).png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            color: #66FFFF;
            font-size: 28px;
        }
    }

    .rightButton {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 30px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 14px;

            .battery {
                width: 130px;
                height: 64px;
                padding: 14px;
                padding-right: 20px;
                margin: 0px 10px;
                background: url("../../assets/BMS/battery.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
            }

            .batteryNum {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .tet {
                    font-weight: 500;
                    font-size: 24px;
                    color: #02BDBC;
                    z-index: 999;
                }

                .win {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40%;
                    height: 100%;
                    background-color: #66ffff;
                }
            }
        }
    }
}

.ellipsis-multiline {
    display: -webkit-box;
    display: -moz-box;
    white-space: pre-wrap;
    word-wrap: break-word; //允许长单词或 URL 地址换行到下一行。
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //显示行数
    overflow: hidden;
}
</style>